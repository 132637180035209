@api = angular.module 'cs.api', ['csConfig']

@api.factory 'ActivityService', (csConfig, $resource, localStorageService) ->

  params = { id: '@id', embedded: '@embedded' }
  Activity = $resource "#{csConfig.chefstepsEndpoint}/api/v0/activities/:id", params,
    'get':
      method: 'GET'
      isArray: false
      headers:
        'Authorization': ->
          userToken = localStorageService.get('user.token')
          if userToken then 'Bearer ' + userToken else ''
        'x-csrf-token': undefined
        'cs-referer': document.referrer


  return Activity
