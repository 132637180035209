@app.directive 'trialCouponSubscriptionButton', ($state, $location, AnalyticsService) ->
  restrict: 'E'
  scope: {}
  bindToController: {
    label: '='
    buttonUrl: '='
    theme: '='
    alreadySubscribedTitle: '='
    alreadySubscribedText: '='
    preCouponCode: '='
    preCode: '='
  }
  controllerAs: 'trialCouponSubscriptionButton'

  controller: ($scope, subscriptionService, ModalService) ->
    @isStudio = $state.currentUser?.studio
    @code = $location.search().premium_code || $location.search().coupon_code
    @showSpinner = false

    @onSubscribeError = (errorMessage) =>
      AnalyticsService.track 'Trial Subscription Button Error', {errorType: errorMessage}
      @error_message = errorMessage
      @showSpinner = false


    @onSuccess = =>
      @takeMeHome()

    @takeMeHome = ->
      window.location = @buttonUrl || "/studiopass"

    @redeem = (code) =>
      if !$state.currentUser
        ModalService.open('login', source: 'trialRedeem')
      else
        @showSpinner = true
        subscriptionService.validateCoupon(code)
          .then (_) =>
            subscriptionService.subscribeTrial(code, @onSubscribeError, @onSuccess)
          .catch (error) =>
            if error.status == 400
              @onSubscribeError(error['data']['message'])
            else
              @onSubscribeError(error)


    $scope.onClick = =>
      if !!@code
        @error_message = null
        @redeem(@code)
      else
        @error_message = 'Please enter a valid code'

    $scope.redeemPreCode = =>
      console.log("Hello")
      if !!@preCouponCode
        @error_message = null
        @redeem(@preCouponCode)
      else
        @error_message = 'Coupon code is empty'

    this

  templateUrl: '/assets/templates/components/customButton/trialCouponSubscriptionButton/trialCouponSubscriptionButton.html'
